<template>
  <div>
    <!--问号 什么是助教-->
    <help
      class="assistant-ps"
      text="帮助老师管理直播间，编辑公告，禁言，踢出捣蛋学员，封号，还能在直播间推送课程售卖"
    ></help>
    <el-card class="box-card">
      <div class="item">
        <div class="head">
          <el-button @click="addPeople">添加助教</el-button>
          <el-input
            v-model.trim="name"
            style="width: 218px; float: right"
            placeholder="输入姓名或手机号搜索"
          ></el-input>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column label="名称">
            <template slot-scope="scope">
              <dl>
                <dt class="course_img">
                  <img :src="scope.row.uphoto" />
                </dt>
                <dd class="course_name">
                  {{ scope.row.uname }}
                </dd>
                <dd v-if="scope.row.student_remarks" class="course_remark">
                  {{ scope.row.student_remarks }}
                </dd>
              </dl>
            </template>
          </el-table-column>
          <el-table-column prop="umobile" label="手机号"></el-table-column>
          <el-table-column prop="course_num" label="所属课程">
            <template slot-scope="scope">
              <span
                @click="theirCourse(scope.row.uid, scope.row.course_num)"
                class="course-type"
              >
                {{ scope.row.course_num }}
                <i class="el-icon-arrow-right"></i>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="regdate" label="注册时间">
            <template slot-scope="scope">
              {{ scope.row.regdate | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="right">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="toggleRemark(scope.row, scope.$index)"
              >
                备注
              </el-button>
              <span
                style="
                  vertical-align: middle;
                  display: inline-block;
                  padding: 0px 10px;
                  padding-bottom: 3px;
                  color: #1b9d97;
                "
              >
                |
              </span>
              <el-button
                type="text"
                size="small"
                @click="
                  dialogDeletePeople({
                    uid: scope.row.uid,
                    is_assistant: 0,
                    type: 1,
                    index: scope.$index,
                  })
                "
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="width: 100%; margin: 30px auto; text-align: center">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="nowPage"
          :total="pageDataTotals"
          v-if="pageDataTotals > 15"
          :page-sizes="[15]"
          :page-size="15"
          @current-change="getJigouAssistant"
        ></el-pagination>
      </div>

      <el-dialog
        title="所属课程"
        :visible.sync="isWatchPeopleDialog"
        customClass="assistantCourse"
      >
        <div
          v-for="(item, index) in assistantCourse"
          :key="item.course_id"
          class="dialog-centent"
        >
          <dl class="dialog-dl">
            <dt class="dialog-dt">
              <img :src="item.photo" />
            </dt>
            <div>
              <dd class="dialog-dd">{{ item.name }}</dd>
              <dd class="dialog-dd">{{ item.member }}人报名</dd>
            </div>
          </dl>
          <div class="dialog-div">
            <el-button
              type="text"
              size="small"
              @click="
                dialogDeletePeople({
                  uid: isWatchPeopleDialog,
                  is_assistant: 0,
                  type: 2,
                  index: index,
                  course_id: item.course_id,
                })
              "
            >
              取消该助教
            </el-button>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title="助教备注"
        :visible.sync="remark.show"
        width="30%"
        custom-class="new-tag"
        :close-on-click-modal="false"
      >
        <div style="margin: 20px 20px; margin-top: 0px">
          <el-input
            maxlength="8"
            show-word-limit
            placeholder="填写备注名"
            v-model.trim="remark.name"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="remark.show = false">取 消</el-button>
          <el-button @click="saveRemark" type="primary">完 成</el-button>
        </span>
      </el-dialog>

      <el-dialog
        custom-class="add-assistant-contain"
        :visible.sync="isAddPeopleDialog"
        :before-close="closeAddAssistant"
      >
        <span slot="title" class="dialog-header">
          <span class="title">添加助教</span>
          <span class="title-text">
            提示：添加助教是将学员设置为助教角色，在创建课程时可选择已添加的助教
          </span>
        </span>
        <el-tabs v-model="activeName">
          <el-tab-pane label="已付费" name="first">
            <div style="margin: 20px; height: 40px">
              <el-input
                v-model.trim="studentName"
                style="width: 218px; float: right"
                @input="searchStudent"
                placeholder="输入学员名称搜索"
              ></el-input>
            </div>
            <empty v-if="addTorialData.length === 0"></empty>
            <div
              class="add-content"
              v-for="(item, index) in addTorialData"
              :key="index"
            >
              <div class="first-content">
                <el-checkbox v-model="selectAssistantUid" :label="item.uid">
                  &nbsp;
                </el-checkbox>
              </div>
              <div>
                <img :src="item.uphoto" class="addHaed-img" />
              </div>
              <div>
                <span class="add-name">{{ item.uname }}</span>
              </div>
              <div class="second-content">
                {{ item.usex }}
              </div>
              <div class="third-content">
                <el-button
                  type="text"
                  size="small"
                  @click="setUserRole([item.uid])"
                >
                  添加
                </el-button>
              </div>
            </div>

            <div style="width: 100%; margin: 30px auto; text-align: center">
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page="DigNowPage"
                :total="digtotals"
                v-if="digtotals > 15"
                :page-sizes="[15]"
                :page-size="15"
                @current-change="DigHandleCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="未付费" name="second">
            <div style="margin: 20px; height: 40px">
              <el-input
                v-model.trim="studentName"
                style="width: 218px; float: right"
                placeholder="输入学员名称搜索"
              ></el-input>
            </div>
            <empty v-if="addTorialData.length === 0"></empty>
            <div
              class="add-content"
              v-for="(item, index) in addTorialData"
              :key="index"
            >
              <div class="first-content">
                <el-checkbox v-model="selectAssistantUid" :label="item.uid">
                  &nbsp;
                </el-checkbox>
              </div>
              <div>
                <img :src="item.uphoto" class="addHaed-img" />
              </div>
              <div>
                <span class="add-name">{{ item.uname }}</span>
              </div>
              <div class="second-content">
                {{ item.usex }}
              </div>
              <div class="third-content">
                <el-button
                  type="text"
                  size="small"
                  @click="setUserRole([item.uid])"
                >
                  添加
                </el-button>
              </div>
            </div>

            <div style="width: 100%; margin: 30px auto; text-align: center">
              <el-pagination
                background
                layout="prev, pager, next"
                :current-page="DigNowPage"
                :total="digtotals"
                v-if="digtotals > 15"
                :page-sizes="[15]"
                :page-size="15"
                @current-change="DigHandleCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeAddAssistant">取 消</el-button>
          <el-button
            type="primary"
            @click="setUserRole(selectAssistantUid, true)"
          >
            添 加
          </el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import background from '@/mixin/background'
import help from '@cm/helpButtton'
import empty from '@cm/Empty'

export default {
  components: {
    help,
    empty,
  },

  data() {
    return {
      name: '',
      studentName: '',
      tableData: [],
      isWatchPeopleDialog: false,
      isAddPeopleDialog: false,
      dialogData: [],
      activeName: 'first',
      addPeopleData: [],
      checked: false,
      addTorialData: [],
      addTorialType: 1,
      digtotals: 0,
      pageDataTotals: 0,
      remark: {
        name: '',
        uid: '',
        show: false,
      },
      DigNowPage: 1,
      nowPage: 1,

      // 助教所属课程
      assistantCourse: [],

      // 选中的助教
      selectAssistantUid: [],
    }
  },

  mixins: [background],

  methods: {
    theirCourse(uid, count) {
      if (count > 0) {
        const that = this
        that.isWatchPeopleDialog = uid

        that.$http({
          name: 'assistantToCourse',
          url: '/User/assistantToCourse',
          data: {
            uid,
          },
          callback({ data }) {
            that.assistantCourse = data
          },
        })
      }
    },

    DigHandleCurrentChange(val) {
      this.DigNowPage = val
      this.addPeople()
    },

    addPeople() {
      const that = this

      that.isAddPeopleDialog = true
      that.$http({
        name: 'getStudentList',
        url: '/User/getStudentList',
        isMultipleSelf: 'replacePrev',
        data: {
          type: that.addTorialType,
          is_assistant: 1,
          page: that.DigNowPage,

          search: that.studentName || '',
        },
        callback({ data, count }) {
          that.addTorialData = data
          that.digtotals = parseInt(count)
        },
        error() {
          that.addTorialData = []
          that.digtotals = 0
        },
      })
    },

    // 搜索助教
    searchStudent() {
      this.DigNowPage = 1
      this.addPeople()
    },

    // 添加助教
    setUserRole(uid, close) {
      const that = this
      that.$http({
        name: 'setUserRole',
        url: '/Organ/setUserRole',
        data: {
          uid,
          is_assistant: 1,
        },
        callback() {
          that.addTorialData = that.addTorialData.filter(
            (item) => uid.indexOf(item.uid) === -1
          )
          that.digtotals -= uid.length
          that.getJigouAssistant()
          if (typeof close === 'boolean') {
            that.isAddPeopleDialog = false
          }
          that.$root.prompt({
            msg: '添加成功',
            type: 'success',
          })
          that.selectAssistantUid = []
        },
      })
    },

    // 关闭添加助教弹窗
    closeAddAssistant() {
      this.isAddPeopleDialog = false
      this.selectAssistantUid = []
    },

    // 取消助教
    dialogDeletePeople({
      uid = '',
      index = '',
      is_assistant = '',
      type = '',
      course_id = '',
    }) {
      this.$confirm(
        '是否取消该课程的当前助教？取消后可在课程管理设置',
        '取消助教',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          name: 'cancelAssistant',
          url: '/Organ/setUserRole',
          data: {
            uid,
            is_assistant,
            type,
            course_id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '取消成功!',
              type: 'success',
            })
            if (course_id) {
              this.assistantCourse.splice(index, 1)
              this.getJigouAssistant()
            } else {
              this.tableData.splice(index, 1)
              this.pageDataTotals -= 1
            }
          },
        })
      })
    },

    toggleRemark({ uid, student_remarks }, $index) {
      this.remark = {
        uid,
        name: student_remarks,
        show: true,
        index: $index,
      }
    },

    // 备注
    saveRemark() {
      //设置备注
      const that = this

      that.$http({
        name: 'StudentRemarks',
        url: 'Organ/studentRemarks',
        data: {
          ex: that.remark.name || '',
          s_id: that.remark.uid,
        },
        callback() {
          that.$root.prompt({
            type: 'success',
            msg: '设置成功',
          })
          that.tableData[that.remark.index].student_remarks =
            that.remark.name || ''
          that.remark.show = false
        },
      })
    },
    //获取助教列表
    getJigouAssistant(page) {
      const that = this
      that.nowPage = typeof page === 'number' ? page : 1

      that.$http({
        name: 'GetJigouAssistant',
        url: '/Organ/getJigouAssistant',
        isMultipleSelf: 'replacePrev',
        data: {
          type: 2,
          search: that.name || '',
          page: that.nowPage,
        },
        callback({ data, allNum }) {
          that.tableData = data
          that.pageDataTotals = parseInt(allNum)
        },
        error() {
          that.tableData = []
          that.pageDataTotals = 0
        },
      })
    },
  },

  created() {
    const that = this
    that.getJigouAssistant()
  },

  watch: {
    activeName: function (val) {
      this.studentName = ''
      this.DigNowPage = 1
      if (val == 'first') {
        this.addTorialType = 1
      } else {
        this.addTorialType = 2
      }
      this.addPeople()
    },

    studentName() {
      if (this.isAddPeopleDialog) {
        this.searchStudent()
      }
    },

    name() {
      this.getJigouAssistant()
    },
  },
}
</script>

<style lang="less" scoped>
.assistant-ps {
  position: fixed;
  top: 18px;
  left: 243px;
  z-index: 9;
}
.box-card {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
}
.head .el-button {
  width: 126px !important;
  height: 42px !important;
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.head .el-button:hover {
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
.el-button--text {
  color: #1b9d97;
}
/deep/
  .el-table.el-table--fit.el-table--enable-row-hover.el-table--enable-row-transition {
  margin: 30px auto;
}
/deep/ td,
th {
  border: 0px !important;
}
/deep/ .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #333;
}
/deep/ .el-table .descending .sort-caret.descending {
  border-top-color: #333 !important;
}
/deep/ .el-table::before {
  height: 0px;
}
/deep/ .el-pagination.is-background {
  width: 50%;

  margin: 0px auto;
}
/deep/ .el-table.el-table--fit.el-table--enable-row-hover {
  margin: 30px auto;
}
.course_remark {
  font-size: 12px;

  color: rgba(153, 153, 153, 1);
  line-height: 16px;
}
.course_name {
  font-size: 14px;

  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.course_img {
  float: left;
  margin-right: 8px;
}

.course_img img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
}
.course-type {
  cursor: pointer;
}
.dialog-dl {
  width: 50%;
  display: flex;
  align-items: center;
}
.dialog-div {
  position: absolute;
  top: 50%;
  right: 0;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
.dialog-dt {
  width: 68px;
  height: 38px;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}
.dialog-centent {
  margin-bottom: 25px;
  position: relative;
}
.dialog-dd {
  line-height: 19px;
}
.title {
  width: 56px;
  height: 19px;
  font-size: 14px;

  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.title-text {
  width: 408px;
  height: 16px;
  font-size: 12px;

  color: rgba(255, 53, 53, 1);
  line-height: 16px;
  margin-left: 30px;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header.is-top {
  margin: 0px;
}
/deep/ .el-tabs__item.is-active {
  color: #0aa29b;
}
/deep/ .el-tabs__item:hover {
  color: #0aa29b;
}

/deep/ .el-tabs__active-bar {
  background-color: #0aa29b;
  height: 1px;
}
/deep/ .el-tabs.el-tabs--top {
}
/deep/ #tab-first {
  padding-left: 20px;
}
/deep/ .el-dialog__body {
  padding-left: 0px;
  padding-right: 0px;
}
.add-content {
  margin: 0px 20px;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
}
.addHaed-img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  vertical-align: middle;
}
/deep/ .el-checkbox {
  vertical-align: middle;
  margin-bottom: 0px;
  float: left;
  margin-top: 10px;
  margin-right: 20px;
}
.addHaed-img {
  float: left;
}
.add-name {
  margin-left: 10px;
}
.first-content {
  width: 23px;
  overflow: hidden;
}
.third-content {
}
.second-content {
  text-align: right;
  flex: 1;
  padding-right: 175px;
}
.add-content {
  margin-bottom: 20px;
}
/deep/ .el-button--primary {
  border-radius: 4px !important;
  color: #fff !important;
  border: 1px solid #1b9d97 !important;
  background-color: #1b9d97 !important;
}
/deep/ .el-button.el-button--default:hover {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
/deep/ .assistantCourse {
  padding: 0 20px;
  width: 800px;
  height: 518px;
  overflow-y: auto;
  box-sizing: border-box;
}
/deep/ .add-assistant-contain .el-tab-pane {
  overflow-y: auto;
  width: 100%;
  height: 400px;
}
</style>
