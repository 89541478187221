<!--
    弹窗问号提示信息 组件
-->
<template>
  <div>
    <div class="help" @click.stop="drawPs = !drawPs"></div>
    <div class="help-ps" v-if="drawPs" @click.stop>
      {{ text }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'helpButton',

  props: {
    text: String
  },

  data() {
    return {
      drawPs: false
    }
  },

  created() {
    window.addEventListener('click', this.hideHelpPs)
  },

  destroyed() {
    window.removeEventListener('click', this.hideHelpPs)
  },

  methods: {
    hideHelpPs() {
      this.drawPs = false
    }
  }
}
</script>
<style scoped>
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
  background: url('~@ass/img/1.1.2/help.png');
}
.help-ps {
  position: absolute;
  left: 30px;
  top: 3px;
  width: 326px;
  z-index: 3;
  font-size: 12px;
  padding: 7px 12px;
  color: rgba(74, 74, 74, 1);
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  line-height: 16px;
  border: 1px solid rgba(230, 230, 230, 1);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.09);
}
</style>
